export default {
    APPLY(state) {
        state.is_applied = true;
    },

    STORE_APPLY_UUID(state, applyUuid) {
        state.apply_uuid = applyUuid;
    },

    STORE_APPLY_TYPE_SEEKER(state) {
        state.apply_type = 'seeker';
    },

    STORE_APPLY_TYPE_NEW(state) {
        state.apply_type = 'new';
    },

    STORE_APPLY_TYPE_GUEST(state) {
        state.apply_type = 'guest';
    },

    STORE_USER(state, user) {
        state.user = user;
    },

    STORE_APPLY_STEP(state, step) {
        state.apply_step = step;
    },

    STORE_APPLY_STEPS_COUNT(state, count) {
        state.apply_steps_count = count;
    },

    STORE_APPLY_PASS_ACTIVATION_ID(state, applyPassActivationId) {
        state.apply_pass_activation_id = applyPassActivationId;
    },

    RESET_APPLY(state) {
        state.user = {};
        state.apply_uuid = '';
        state.is_applied = false;
        state.is_basic_questions_open = false;
        state.is_onboarding_questions_open = false;
        state.is_custom_questions_open = false;
        state.is_signin_modal_open = false;
        state.custom_question_step = 1;
        state.is_custom_question_loading = false;
        state.questions = [];
    },

    CHECK_RESUME_REVIEW(state) {
        state.is_resume_review = true;
    },

    UNCHECK_RESUME_REVIEW(state) {
        state.is_resume_review = false;
    },

    CHECK_RESUME_DEFAULT(state) {
        state.make_resume_default = true;
    },

    UNCHECK_RESUME_DEFAULT(state) {
        state.make_resume_default = false;
    },
};
